<template>
  <div class="home-page">
    <!-- <div class="home-banner swiper-container">
      <div class="swiper-wrapper" style="position: relative;">
        <div class="swiper-slide" v-for="(item,index) in swiperArr" :key="index">
          <a :href="item.url" class="banner-img" target="_blank" :style="{'backgroundImage': `url(${item.host}${item.image})` }" @click="sensors.track( 'LY_home_click',{AppName: this.$store.state.AppName, click_content: 'banner', page_id:'', is_login: false, platform: this.$store.state.platform})">
          </a>
        </div>
      </div>
      <div class="swiper-pagination home-pagination"></div>
    </div> -->
    <!-- 路演列表 -->
    <div class="roadshow-index">
      <div class="roadshow-choice">
        <span class="roadshow-name road-type-title">路演类型</span>
        <div class="roadshow-type">
          <div class="roadshow-type-item" :class="{'item-active': !form.templateId}" @click="checkTab('全部',-1)">
            <span class="roadshow-type-item-type ">
              全部
            </span>
            <span class="roadshow-type-board" :class="{noShowBack: templateIndex === 0 || templateIndex === 1}">
            </span>
          </div>
          <div v-for="(item,index) in tabList" :key="item.id" class="roadshow-type-item" :class="{'item-active': form.templateId === item.id}" @click="checkTab(item.name,index, item)">
            <span class="roadshow-type-item-type ">
              {{item.name}}
            </span>
            <span class="roadshow-type-board" :class="{noShowBack: templateIndex === index+1 || templateIndex === index+2}">
            </span>
          </div>
        </div>
      </div>
      <div class="roadshow-choice">
        <span class="roadshow-name road-type-title">路演形式</span>
        <div class="roadshow-type">
          <div class="roadshow-type-item" :class="{'item-active': !form.type}" @click="checkKind('全部',-1)">
            <span class="roadshow-type-item-type ">
              全部
            </span>
            <span class="roadshow-type-board" :class="{noShowBack: kindIndex === 0 || kindIndex === 1}">
            </span>
          </div>
          <div v-for="(item,index) in kindList" :key="item.id" class="roadshow-type-item" :class="{'item-active': form.type === item.id}" @click="checkKind(item.name,index, item)">
            <span class="roadshow-type-item-type ">
              {{item.name}}
            </span>
            <span class="roadshow-type-board" :class="{noShowBack: kindIndex === index+1 || kindIndex === index+2}"></span>
          </div>
        </div>
      </div>
      <div class="roadshow-choice">
        <div class="search-time">
          <span class="roadshow-name">选择时间</span>
          <div class="select-time">
            <i class="date-time start-date-icon"></i>
            <!-- <input @focus='getStartDate' v-model="form.startPublishedDate" type="text" id="start_date" class="start-date" readonly="readonly" placeholder="开始日期">  -->
            <input v-model="form.startPublishedDate" type="text" id="start_date" class="start-date" readonly="readonly" placeholder="开始日期"> 
            <span class="select-time-lion">

            </span>
            <!-- <input @focus='getEndDate' v-model="form.endPublishedDate" type="text" id="end_date" class="end-date" readonly="readonly" placeholder="结束日期"> -->
            <input v-model="form.endPublishedDate" type="text" id="end_date" class="end-date" readonly="readonly" placeholder="结束日期">
            <i class="date-time end-date-icon"></i>
          </div>
        </div>
        <div class="search-time">
          <span class="roadshow-name">关键字搜索</span>
          <div class="select-time">
            <input class="search-input" v-model="form.keywords" type="text" placeholder="请输入关键字"/>
          </div>
        </div>
        <span class="search-button" @click="search">搜索</span>
      </div>
      <div class="video">
        <div v-if="roldList.length">
          <div class="video-item" v-for="(item,index) in roldList" :key="item.id">
            <div class="video-item-content">
            <a @click="toDetail(item)" :to="`/pages/luyan-page/luyanDetails?id=${item.id}`" target="_blank">
              <div class="img_box">
                <img :src="item.thumb" alt="" @error="item.error = true" v-if="!item.error">
                <img src="~@/assets/img/indexImg/itembg.png" alt="" v-else>
                <!-- <i class="img_icon"></i> -->
                <em class="tip">{{getItemType(item.templateId, item.publishedTime)}}</em>
              </div>
            </a>
              <div @click="toDetail(item)" class="item-info">
                <a :to="`/pages/luyan-page/luyanDetails?id=${item.id}`" target="_blank">
                <h2>{{item.title}}</h2>
                </a>
                <div class="item-footnote">
                  <div class="footnote-scoure">
                    <span class="footnote-company-name">
                      <span class="footnote-company-nameData">{{item.officeName}}</span>
                      <template v-if="item.officeCode">
                        <span  class="footnote-company-nameData">({{item.officeCode}})</span>
                      </template>
                    </span>
                    <span class="collect-start-active" v-if="item.collectStatus" @click="collectRold(item, index)"></span>
                    <span class="collect-start" v-else @click="collectRold(item, index)"></span>
                    </div>
                  <div class="footnote-timer">{{item.publishedTime}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <NoData v-else />
        <p class="loading-more" v-if="!finished && !loadMore && roldList.length && form.pageNo>=2" @click="getMore">加载更多</p>
        <p class="load-finish" v-if="finished && roldList.length && form.pageNo>2">加载完毕</p>
        <p class="loading-data" v-if="loadMore && roldList.length"><img src="@/assets/img/article_list/loading.gif"/>加载中...</p>
      </div>
    </div>
    <!-- <Tips v-if="abnormalTip" :isLoginLink="isLoginLink" :abnormalTxt="abnormalTxt" @closeModel="closeModel"/> -->
  </div>
</template>
<script>
import "@/assets/css/index.less";
import 'swiper/dist/idangerous.swiper.css'
import Swiper from 'swiper/dist/idangerous.swiper.js'
import { setCatalogTree } from "@/utils/store-data";
import { getBannerList, getRoldTabList, getRoldList, collectRold, roadshowType } from "@/api/rold";
import NoData from '@/components/NoData'
import BuryingPont from "@/mixins/buryingPoint.js";
// import Tips from '@/components/Tips'
export default {
  mixins: [BuryingPont],
  data() {
    return {
      form: {
        pageNo: 1,
        pageSize: 8,
        startPublishedDate: '',
        endPublishedDate: '',
        templateId: '',
        type: '',
        keywords: ''
      },
      tabList: [],
      kindList: [],
      roldList: [],
      swiperArr: [],

      finished: false,
      loadMore: false,
      loading: false,
      startTime: '',
      // abnormalTip: false,
      // isLoginLink: false,
      // abnormalTxt: '',
      templateIndex: 0,
      kindIndex: 0,
      loadList: false,
    };
  },
  components: {NoData},
  beforeRouteLeave(to, from, next) {
    window.removeEventListener('scroll', this.scrollFns)
    next()
  },
  created() {
    
    setCatalogTree(this.$store);
    this.getBannerList();
    this.roadshowType();
    // this.getRoldList();
    this.loadSecond();
    this.getRoldTabList();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollFns)
    var layui = window.layui
    var that = this;

    layui.use('laydate', function(){
      var laydate = layui.laydate;
      var startDate = laydate.render({
        elem: '#start_date',
        // show: true,
        theme: '#005BAC',
        btns: ['clear', 'confirm'],
        max: '2099-12-31',
        done: function (value, date) {
          console.log(date)
          Object.assign(that.form, {
            startPublishedDate: value
          })
          if(date) {
            endDate.config.min ={    
              year:date.year || '1990',     
              month:date.month-1>=0?date.month-1:'1',     
              date: date.date || '1' 
            }
          }  
        }
      })

      var endDate = laydate.render({
          elem: '#end_date',
          // show: true,
          theme: '#005BAC',
          btns: ['clear', 'confirm'],
          min: '1900-1-1',
          done: function (value,date) {
            console.log(date)
            Object.assign(that.form, {
              endPublishedDate: value
            })
            startDate.config.max={    
              year:date.year || '2099',     
              month:date.month-1 || '12',    
              date: date.date  || '31'
            }
          }
        });
    })
    
  },
  methods: {
    //收藏
    collectRold(item, index) {
      if(this.$store.state.token) {
        if(this.$store.state.token.indexOf('BC_') != -1){
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可收藏，请';
          // this.isLoginLink = true;
          this.$message({tipTxt:'企业用户不可收藏，请',isLink:true,loginTxt:'登录个人账户'});
        }else{
          collectRold({
            id:item.id,
            type: '10'
          }).then(res => {
            if(res.code == 0) this.roldList[index].collectStatus = !this.roldList[index].collectStatus
            // 收藏埋点
            if(this.roldList[index].collectStatus){
              this.sensors.track( 'LY_collection_click',{
                AppName: this.$store.state.AppName,  
                click_content: '收藏',
                collect_title: item.title,
                is_login: true, 
                platform: this.$store.state.platform
              })
            }
            // 收藏埋点
            if(!this.roldList[index].collectStatus){
              this.sensors.track( 'LY_cancel_collection_click',{
                AppName: this.$store.state.AppName,  
                click_content: '取消收藏',
                collect_title: item.title,
                is_login: true, 
                platform: this.$store.state.platform
              })
            }
          })
        }
      } else {
        this.$router.push('/login')
      }
    },
    // 路演形式列表
    roadshowType(){
      roadshowType({types: 'roadshow_type'}).then(res=>{
        this.kindList = res.data;
        // this.form.type = res.data[0].id;
        this.getRoldList();
      }).catch(err=>{
        console.log(err);
      })
    },
    // 关闭弹窗
    // closeModel(data){
    //   this.abnormalTxt = data.tipText;
    //   this.isLoginLink = data.linkFlag;
    //   this.abnormalTip = data.tipFlag;
    // },
    getBannerList() {
      getBannerList().then(res => {
        this.swiperArr = res.data
      }).finally(() => {
        new Swiper('.home-banner',{
          autoplay: 5000,
          speed: 300,
          loop: this.swiperArr.length>1?true:false,
          pagination: '.home-pagination',
          paginationClickable :true,
          autoplayDisableOnInteraction: false,
          onSlideChangeEnd: function(swiper){
            swiper.startAutoplay();
          }
        })
      })
    },
    getStartDate() {
      var layui = window.layui
      var that = this;
      layui.use('laydate', function(){
        var laydate = layui.laydate;
        laydate.render({
          elem: '#start_date',
          show: true,
          theme: '#005BAC',
          btns: ['clear', 'confirm'],
          max: that.form.endPublishedDate || '2099-12-31',
          done: function (value) {
            Object.assign(that.form, {
              startPublishedDate: value
            })
          }
        });
      })
    },
    getEndDate() {
      var layui = window.layui
      var that = this;
      layui.use('laydate', function(){
        var laydate = layui.laydate;
        laydate.render({
          elem: '#end_date',
          show: true,
          theme: '#005BAC',
          btns: ['clear', 'confirm'],
          min: that.form.startPublishedDate || '1900-1-1',
          done: function (value) {
            Object.assign(that.form, {
              endPublishedDate: value
            })
          }
        });
      })
    },
    //加载更多
    getMore() {
      this.loading = true;
      this.finished = false;
      this.loadMore = true;
      this.form.pageNo++
      this.getRoldList()
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond(){
      var that = this;
      var loadData = setInterval(function(){
        if(!this.finished && !that.loadList && that.form.pageNo < 2){
          that.scrollFns();
        }
        if(that.form.pageNo >= 2 || this.finished){
          clearInterval(loadData)
        }
      },100)
    },
  
    // 滚动事件
    scrollFns(){
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollH-clientH-toTop<322 && !this.loading && !this.finished && this.form.pageNo<2 && this.roldList.length >= this.form.pageSize){
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.form.pageNo++
        this.getRoldList()
      }
    },
    //切换tab
    checkTab(name,index, data) {
      
      this.templateIndex = index+1
      // if(data && data.id != this.form.templateId) {
        Object.assign(this.form, {
          pageNo: 1,
          templateId: data ? data.id : '',
          // startPublishedDate: '',
          // endPublishedDate: '',
        })
        this.finished = false
        
        this.getRoldList()
        this.loadSecond();
      // }
      // 切换类型埋点
      this.sensors.track( 'LY_home_click',
      {AppName: this.$store.state.AppName, click_content: '路演类型', first_level_column:name, page_id:'', is_login: false, platform: this.$store.state.platform})
    },
    // 切换形式
    checkKind(name,index,data){
      this.kindIndex = index+1;
      Object.assign(this.form, {
        pageNo: 1,
        type: data ? data.id : '',
      })
      this.finished = false;
      this.getRoldList()
      this.loadSecond();
      // 切换形式埋点
      this.sensors.track( 'LY_home_click',
      {AppName: this.$store.state.AppName, click_content: '路演形式', first_level_column:name, page_id:'', is_login: false, platform: this.$store.state.platform})
    },
    //搜索
    search() {
      // if(this.form.templateId || (this.form.startPublishedDate && this.form.endPublishedDate)) {
        this.form.pageNo = 1
        this.finished = false;
        this.getRoldList('search')
        this.loadSecond()
      // }
      
    },
    //获取tab列表
    getRoldTabList() {
      getRoldTabList().then(res => {
        this.tabList = res.data
      })
    },
    //获取路演列表
    getRoldList(txt) {
      if(!this.loadList){
        this.loadList = true;
        getRoldList(this.form).then(res => {
          if(this.form.pageNo === 1) this.roldList = []
          if(res.data && res.data.length) {
            res.data.forEach(item => item.error = false)
            this.roldList = this.roldList.concat(res.data)
            // if(this.roldList.length >= res.count) this.finished = true;
            if(res.data.length < this.form.pageSize) this.finished = true;
          } else if(res.data.length === 0) {
            this.finished = true
          }
          // 浏览埋点
          this.sensors.track( 'LY_view_list_page',{
            AppName: this.$store.state.AppName, 
            page_id: '', 
            type: '视频',
            number: res.count,
            total_page: res.count % this.form.pageSize <= 0 ? res.count / this.form.pageSize : parseInt(res.count / this.form.pageSize) + 1,
            is_login: false, 
            platform: this.$store.state.platform
          })
          // 搜索埋点
          if(txt&&txt=='search'){
            this.sensors.track( 'LY_search_result' ,{
              AppName: this.$store.state.AppName, 
              is_login: false, 
              has_result: res.count > 0 ? true : false,
              platform: this.$store.state.platform,
              search_starttime: this.form.startPublishedDate,
              search_endtime: this.form.endPublishedDate,
              search_result_num: res.count,
            })
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadMore = false;
          this.loadList = false;
        })
      }
    },
    getItemType(type, publishedTime) {
      if (new Date(publishedTime).getTime() > new Date().getTime()) return '活动预告'
      var data = this.tabList.filter(item => {
        return item.id == type
      })
      if(data.length) return data[0].name
    },
    async toDetail(item) {
      let isIng = false;
      if (item.endTime) {
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();
        const hour = new Date().getHours();
        const minute = new Date().getMinutes();
        if (year > item.endTime[0]) {
          isIng = false;
        } else {
          isIng = true;
          if (month > item.endTime[1]) {
            isIng = false;
          } else {
            isIng = true;
            if (day > item.endTime[2]) {
              isIng = false;
            } else {
              isIng = true;
              if (hour > item.endTime[3]) {
                isIng = false;
              } else {
                isIng = true;
                if (minute > item.endTime[4]) {
                  isIng = false;
                } else {
                  isIng = true;
                }
              }
            }
          }
        }
      }
      if (item.description && item.description.indexOf('https://wx.easy-board.com.cn') > -1 && (item.startTime || item.endTime) && isIng) {
        if (!this.$store.state.token) {
          this.$router.push("/login");
          return;
        }
        let res;
        if (this.$store.state.token.indexOf("BC_") != -1) {
          res = await this.$store.dispatch("getBusinessInfo");
        } else {
          res = await this.$store.dispatch("getUserInfo");
        }
        const url = item.description?.indexOf('?' > -1) ? `${item.description}&userId=${res.userId}&realName=${res.username}` : `${item.description}?userId=${res.userId}&realName=${res.username}`
        window.open(url, "_blank");
        return
      }
      var detailUrl = this.$router.resolve({path:'/pages/luyan-page/luyanDetails',query:{id:item.id,columnId:452}});
      window.open(detailUrl.href, "_blank");
    }
  }
};
</script>
